import Vue from "vue";
import VueLocalStorage from "vue-localstorage";
import VueRouter from "vue-router";
import store from "./store";

import PageGuest from "./components/PageGuest.vue";
import PageContent from "./components/PageContent.vue";

import PageError from "./views/error/Page.vue";
import PageGuestLogin from "./views/guest_login/Page.vue";
import PageBusOrder from "./views/bus_order/Page.vue";
import PageBusPackage from "./views/bus_package/Page.vue";
import PageDictFrom from "./views/dict_from/Page.vue";
import PageDictType from "./views/dict_type/Page.vue";
import PageDictStatus from "./views/dict_status/Page.vue";
import PageSetOrg from "./views/set_org/Page.vue";
import PageSetPeople from "./views/set_people/Page.vue";
import PageSetRole from "./views/set_role/Page.vue";

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueLocalStorage);
Vue.use(VueRouter);

const checkIsLogin = () => {
  const session = Vue.localStorage.get("session")
  return !!session;
}

const router = new VueRouter({
  mode: "history",
  routes: [
    { 
      path: '/', 
      redirect: '/backend/bus/order'
    },
    {
      path: "/backend",
      component: PageGuest,
      children: [
        {
          path: 'guest/login',
          meta: { title: "账号登录" },
          component: PageGuestLogin,
        },
      ]
    },
    {
      path: "/backend",
      component: PageContent,
      children: [
        {
          path: 'bus/order',
          meta: { title: "订单管理" },
          component: PageBusOrder,
        },
        {
          path: 'bus/package',
          meta: { title: "营销案管理" },
          component: PageBusPackage,
        },
        {
          path: 'dict/from',
          meta: { title: "订单来源信息" },
          component: PageDictFrom,
        },
        {
          path: 'dict/type',
          meta: { title: "订单类型信息" },
          component: PageDictType,
        },
        {
          path: 'dict/status',
          meta: { title: "订单状态信息" },
          component: PageDictStatus,
        },
        {
          path: 'set/people',
          meta: { title: "人员管理" },
          component: PageSetPeople,
        },
        {
          path: 'set/org',
          meta: { title: "组织架构" },
          component: PageSetOrg,
        },
        {
          path: 'set/role',
          meta: { title: "角色权限" },
          component: PageSetRole,
        },
      ]
    },
    { 
      path: '*', 
      meta: { title: "404" },
      component: PageError,
    },
  ],
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  store.commit("updateCurrentPage", {
    title: to.meta.title,
    url: to.path,
  });
  if (to.path === '/backend/guest/login') {
    next()
  } else {
    if (checkIsLogin()) {
      next()
    } else {
      next({ path: '/backend/guest/login', replace: true })
    }
  }
});

export default router;
