import {reqGet, reqPost} from "./request";

export const adminAccountDetail = (param) => reqGet('/admin/account/detail', param);
export const adminAccountLogin = (param) => reqPost('/admin/account/login', param);
export const adminAccountLogout = (param) => reqPost('/admin/account/logout', param);

// 用户
export const adminProfileBasicDetail = (param) => reqGet('/admin/profile/basic-detail', param);
export const adminProfileBasic = (param) => reqPost('/admin/profile/basic', param);
export const adminProfilePassword = (param) => reqPost('/admin/profile/password', param);

// 角色
export const adminRoleList = (param) => reqGet('/admin/role/list', param);
export const adminRoleCreate = (param) => reqPost('/admin/role/create', param);
export const adminRoleUpdateDetail = (param) => reqGet('/admin/role/update-detail', param);
export const adminRoleUpdate = (param) => reqPost('/admin/role/update', param);
export const adminRoleDelete = (param) => reqPost('/admin/role/delete', param);
export const adminRoleRouteDetail = (param) => reqGet('/admin/role/route-detail', param);
export const adminRoleRoute = (param) => reqPost('/admin/role/route', param);

// 组织
export const adminOrgList = (param) => reqGet('/admin/org/list', param);
export const adminOrgCreate = (param) => reqPost('/admin/org/create', param);
export const adminOrgUpdateDetail = (param) => reqGet('/admin/org/update-detail', param);
export const adminOrgUpdate = (param) => reqPost('/admin/org/update', param);
export const adminOrgDelete = (param) => reqPost('/admin/org/delete', param);
export const adminOrgSeeDetail = (param) => reqGet('/admin/org/see-detail', param);
export const adminOrgSee = (param) => reqPost('/admin/org/see', param);

// 人员
export const adminPeopleListSelect = (param) => reqGet('/admin/people/list-select', param);
export const adminPeopleList = (param) => reqGet('/admin/people/list', param);
export const adminPeopleCreateDetail = (param) => reqGet('/admin/people/create-detail', param);
export const adminPeopleCreate = (param) => reqPost('/admin/people/create', param);
export const adminPeopleUpdateDetail = (param) => reqGet('/admin/people/update-detail', param);
export const adminPeopleUpdate = (param) => reqPost('/admin/people/update', param);
export const adminPeopleUpdatePassword = (param) => reqPost('/admin/people/update-password', param);
export const adminPeopleDelete = (param) => reqPost('/admin/people/delete', param);

// 状态
export const adminOrderStatusList = (param) => reqGet('/admin/order-status/list', param);
export const adminOrderStatusCreate = (param) => reqPost('/admin/order-status/create', param);
export const adminOrderStatusUpdateDetail = (param) => reqGet('/admin/order-status/update-detail', param);
export const adminOrderStatusUpdate = (param) => reqPost('/admin/order-status/update', param);
export const adminOrderStatusDelete = (param) => reqPost('/admin/order-status/delete', param);

// 来源
export const adminOrderFromList = (param) => reqGet('/admin/order-from/list', param);
export const adminOrderFromCreate = (param) => reqPost('/admin/order-from/create', param);
export const adminOrderFromUpdateDetail = (param) => reqGet('/admin/order-from/update-detail', param);
export const adminOrderFromUpdate = (param) => reqPost('/admin/order-from/update', param);
export const adminOrderFromDelete = (param) => reqPost('/admin/order-from/delete', param);

// 类型
export const adminOrderTypeList = (param) => reqGet('/admin/order-type/list', param);
export const adminOrderTypeCreate = (param) => reqPost('/admin/order-type/create', param);
export const adminOrderTypeUpdateDetail = (param) => reqGet('/admin/order-type/update-detail', param);
export const adminOrderTypeUpdate = (param) => reqPost('/admin/order-type/update', param);
export const adminOrderTypeDelete = (param) => reqPost('/admin/order-type/delete', param);

// 套餐
export const adminPackageList = (param) => reqGet('/admin/package/list', param);
export const adminPackageCreate = (param) => reqPost('/admin/package/create', param);
export const adminPackageUpdateDetail = (param) => reqGet('/admin/package/update-detail', param);
export const adminPackageUpdate = (param) => reqPost('/admin/package/update', param);
export const adminPackageDelete = (param) => reqPost('/admin/package/delete', param);
export const adminPackageFieldDetail = (param) => reqGet('/admin/package/field-detail', param);
export const adminPackageField = (param) => reqPost('/admin/package/field', param);

// 套餐
export const adminOrderListPeople = (param) => reqGet('/admin/order/list-people', param);
export const adminOrderListPackage = (param) => reqGet('/admin/order/list-package', param);
export const adminOrderListSelect = (param) => reqGet('/admin/order/list-select', param);
export const adminOrderList = (param) => reqGet('/admin/order/list', param);
export const adminOrderCreatePackage = (param) => reqGet('/admin/order/create-package', param);
export const adminOrderCreateDetail = (param) => reqGet('/admin/order/create-detail', param);
export const adminOrderCreate = (param) => reqPost('/admin/order/create', param);
export const adminOrderUpdateDetail = (param) => reqGet('/admin/order/update-detail', param);
export const adminOrderUpdate = (param) => reqPost('/admin/order/update', param);
export const adminOrderStatus = (param) => reqPost('/admin/order/status', param);
export const adminOrderDelete = (param) => reqPost('/admin/order/delete', param);
export const adminOrderExport = (param) => reqPost('/admin/order/export', param);
