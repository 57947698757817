<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">营销案信息</h3>
            <div class="box-tools pull-right">
              <el-button v-if="auth.adminPeopleCreate" @click="append({id: 0, level: 0})">新增类型</el-button>
            </div>
          </div>
          <div class="box-body" v-if="auth.adminPackageList">
            <el-form :inline="true">
              <el-form-item label="搜索">
                <el-input v-model="query.keyword" placeholder="关键字搜索"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="querySelect">查询</el-button>
              </el-form-item>
            </el-form>
            <el-tree
                v-loading="listLoading"
                :data="list"
                :props="defaultProps"
                :expand-on-click-node="false"
                :default-expanded-keys="expanded"
                node-key="id"
                @node-drop="handleDrop"
                @node-expand="select"
                @node-collapse="delSelect"
                draggable
            >
              <div class="custom-tree-node" slot-scope="{ node, data }">
                <div class="custom-tree-node-name">{{ data.name }} <span v-if="data.promoCode !== ''"
                                                                         style="color: #0d6aad;">({{
                    data.promoCode
                  }})</span>
                </div>
                <div>
                  <el-button
                      v-if="auth.adminPackageCreate"
                      type="text"
                      size="mini"
                      :disabled="addLoading"
                      @click="append(data)">
                    <span v-if="data.level === 1">新增业务档次</span>
                    <span v-if="data.level === 2">新增业务营销案</span>
                  </el-button>
                  <el-button
                      type="text"
                      size="mini"
                      v-if="data.level === 1 && auth.adminPackageField"
                      :disabled="fieldLoading"
                      @click="field(data)">
                    设置字段
                  </el-button>
                  <el-button
                      type="text"
                      size="mini"
                      v-if="auth.adminPackageUpdate"
                      :disabled="editLoading"
                      @click="edit(data)">
                    修改
                  </el-button>
                  <el-button
                      type="text"
                      size="mini"
                      v-if="data.is_del && auth.adminPackageDelete"
                      :disabled="delLoading"
                      @click="remove(data)">
                    删除
                  </el-button>
                </div>
              </div>
            </el-tree>
          </div>
          <div class="box-body" v-if="!auth.adminPackageList">
            <div>没有权限</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新增 -->
    <div v-if="auth.adminPackageCreate">
      <el-dialog
          title="新增"
          :modal-append-to-body="true"
          :append-to-body="true"
          :close-on-click-modal="false"
          :visible.sync="addStatus"
          v-if="addStatus"
      >
        <el-form ref="packageAddForm" label-width="120px" :model="addForm">
          <el-form-item
              v-if="addForm.level === 0"
              label="业务类型"
              prop="name"
              :rules="[{ required: true, message: '请输入业务类型', trigger: 'blur' }]"
          >
            <el-input v-model="addForm.name" placeholder="请输入业务类型"></el-input>
          </el-form-item>
          <el-form-item
              v-if="addForm.level === 1"
              label="业务档次"
              prop="name"
              :rules="[{ required: true, message: '请输入业务档次', trigger: 'blur' }]"
          >
            <el-input v-model="addForm.name" placeholder="请输入业务类型"></el-input>
          </el-form-item>
          <el-form-item
              v-if="addForm.level === 2"
              label="营销案名称"
              prop="name"
              :rules="[{ required: true, message: '请输入营销案名称', trigger: 'blur' }]"
          >
            <el-input v-model="addForm.name" placeholder="请输入业务类型"></el-input>
          </el-form-item>
          <el-form-item
              v-if="addForm.level === 0"
              label="重复时长"
              prop="name"
              :rules="[{ required: true, message: '请输入重复时长', trigger: 'blur' }]"
          >
            <el-input v-model="addForm.repeatDuration" placeholder="请输入重复时长"></el-input>
          </el-form-item>
          <el-form-item
              v-if="addForm.level === 2"
              label="营销案编码"
              prop="name"
              :rules="[{ required: true, message: '请输入营销案编码', trigger: 'blur' }]"
          >
            <el-input v-model="addForm.promoCode" placeholder="请输入营销案编码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="appendFinish">立即创建</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>

    <!-- 修改 -->
    <div v-if="auth.adminPackageUpdate">
      <el-dialog
          title="修改"
          :modal-append-to-body="true"
          :append-to-body="true"
          :close-on-click-modal="false"
          :visible.sync="editStatus"
          v-if="editStatus"
      >
        <el-form ref="packageEditForm" label-width="120px" :model="editForm">
          <el-form-item
              v-if="editForm.level === 1"
              label="业务类型"
              prop="name"
              :rules="[{ required: true, message: '请输入业务类型', trigger: 'blur' }]"
          >
            <el-input v-model="editForm.name" placeholder="请输入业务类型"></el-input>
          </el-form-item>
          <el-form-item
              v-if="editForm.level === 2"
              label="业务档次"
              prop="name"
              :rules="[{ required: true, message: '请输入业务档次', trigger: 'blur' }]"
          >
            <el-input v-model="editForm.name" placeholder="请输入业务类型"></el-input>
          </el-form-item>
          <el-form-item
              v-if="editForm.level === 1"
              label="重复时长"
              prop="name"
              :rules="[{ required: true, message: '请输入重复时长', trigger: 'blur' }]"
          >
            <el-input v-model="editForm.repeatDuration" placeholder="请输入重复时长"></el-input>
          </el-form-item>
          <el-form-item
              v-if="editForm.level === 3"
              label="营销案名称"
              prop="name"
              :rules="[{ required: true, message: '请输入营销案名称', trigger: 'blur' }]"
          >
            <el-input v-model="editForm.name" placeholder="请输入营销案名称"></el-input>
          </el-form-item>
          <el-form-item
              v-if="editForm.level === 3"
              label="营销案编码"
              prop="name"
              :rules="[{ required: true, message: '请输入营销案编码', trigger: 'blur' }]"
          >
            <el-input v-model="editForm.promoCode" placeholder="请输入营销案编码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="editFinish">保存修改</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>

    <!-- 字段 -->
    <div v-if="auth.adminPackageField">
      <el-dialog
          title="设置字段"
          :modal-append-to-body="true"
          :append-to-body="true"
          :close-on-click-modal="false"
          :visible.sync="fieldStatus"
          v-if="fieldStatus"
      >
        <el-form label-width="120px">
          <el-form-item label="选择字段">
            <el-checkbox-group v-model="fieldSelect">
              <el-checkbox v-for="(item, i) in fieldOptionalList" :key="i" :label="item.id">{{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="fieldFinish">保存修改</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import qs from "qs";
import {
  adminPackageDelete,
  adminPackageList,
  adminPackageUpdateDetail,
  adminPackageUpdate,
  adminPackageCreate,
  adminPackageFieldDetail,
  adminPackageField
} from "../../api"

export default {
  components: {},
  data() {
    return {
      user: {},
      auth: {},
      params: {},
      query: {
        key: '',
        keyword: ''
      },
      expanded: [],
      list: [],
      listLoading: false,
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      // 新增
      addStatus: false,
      addLoading: false,
      addForm: {
        parentId: 0,
        level: 0,
        name: '',
        repeatDuration: '',
        promoCode: ''
      },
      // 修改
      optionalList: [],
      editStatus: false,
      editLoading: false,
      editForm: {
        id: '',
        parentId: 0,
        level: 0,
        name: '',
        repeatDuration: '',
        promoCode: ''
      },
      // 删除
      delLoading: false,
      // 字段
      fieldOptionalList: [],
      fieldLoading: false,
      fieldStatus: false,
      fieldSelect: [],
      fieldForm: {
        id: '',
        field: ''
      }
    };
  },
  computed: mapState({
    config: (state) => state.config,
  }),
  mounted() {
    const _this = this;
    _this.query = Object.assign(_this.query, _this.$route.query);
    _this.params = Object.assign(_this.params, _this.$route.params);
    if (_this.query.key !== '') {
      _this.expanded = [];
      _this.query.key.split(',').forEach(item => {
        _this.expanded.push(parseInt(item))
      })
    }
    _this.authInit(_this).then((res) => {
      _this.user = res.user;
      _this.auth = res.auth;
      if (_this.auth.adminPackageList) {
        _this.loadList();
      }
    });
  },
  methods: {
    ...mapActions(["authInit"]),
    updateUrl() {
      const _this = this;
      const url = _this.$route.path + "?" + qs.stringify(_this.query);
      _this.$router.replace({path: url});
    },
    // 获取列表
    loadList() {
      const _this = this;
      _this.listLoading = true;
      adminPackageList(_this.query)
          .then(res => {
            _this.listLoading = false;
            _this.list = res.packageList;
            _this.updateUrl();
          })
          .catch(err => {
            _this.listLoading = false;
            _this.$message.error(err.msg);
          })
    },
    querySelect() {
      const _this = this;
      if (_this.auth.adminPackageList) {
        _this.loadList();
      }
    },
    select(item) {
      const _this = this;
      if (!_this.expanded.includes(item.id)) {
        _this.expanded.push(item.id);
        _this.query.key = _this.expanded.join(',')
        _this.updateUrl();
      }
    },
    delSelect(item) {
      const _this = this;
      if (_this.expanded.includes(item.id)) {
        _this.expanded = _this.expanded.filter(a => a !== item.id);
        _this.query.key = _this.expanded.join(',')
        _this.updateUrl();
      }
    },
    // 新增
    append(item) {
      const _this = this;
      _this.addForm.parentId = item.id;
      _this.addForm.level = item.level;
      _this.addForm.name = '';
      _this.addForm.promoCode = '';
      _this.addForm.repeatDuration = '';
      _this.addStatus = true;
    },
    appendFinish() {
      const _this = this;
      _this.$refs.packageAddForm.validate((valid) => {
        if (valid) {
          _this.addLoading = true;
          adminPackageCreate(_this.addForm)
              .then(res => {
                _this.addLoading = false;
                _this.addStatus = false;
                _this.loadList();
                _this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
              })
              .catch(err => {
                _this.addLoading = false;
                _this.$message.error(err.msg);
              })
        } else {
          return false;
        }
      });
    },
    // 修改
    edit(item) {
      const _this = this;
      _this.editLoading = true;
      adminPackageUpdateDetail({id: item.id})
          .then(res => {
            _this.editLoading = false;
            _this.editStatus = true;
            _this.editForm.id = res.package.id;
            _this.editForm.parentId = res.package.parentId;
            _this.editForm.level = res.package.level;
            _this.editForm.name = res.package.name;
            _this.editForm.promoCode = res.package.promoCode;
            _this.editForm.repeatDuration = res.package.repeatDuration;
            _this.optionalList = res.optionalList;
          })
          .catch(err => {
            _this.editLoading = false;
            _this.$message.error(err.msg);
          })
    },
    editFinish() {
      const _this = this;
      _this.$refs.packageEditForm.validate((valid) => {
        if (valid) {
          _this.editLoading = true;
          adminPackageUpdate(_this.editForm)
              .then(res => {
                _this.editLoading = false;
                _this.editStatus = false;
                _this.loadList();
                _this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
              })
              .catch(err => {
                _this.editLoading = false;
                _this.$message.error(err.msg);
              })
        } else {
          return false;
        }
      })
    },
    handleDrop(raw, to, type) {
      const _this = this;
      _this.$confirm('是否执行当前拖动操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.editLoading = true;
        _this.editForm.id = raw.data.id;
        _this.editForm.level = raw.data.level;
        _this.editForm.name = raw.data.name;
        _this.editForm.promoCode = raw.data.promoCode;
        _this.editForm.repeatDuration = raw.data.repeatDuration;
        if (type === 'inner') {
          _this.editForm.parentId = to.data.id;
        } else {
          _this.editForm.parentId = to.data.parentId;
        }
        adminPackageUpdate(_this.editForm)
            .then(res => {
              _this.editLoading = false;
              _this.editStatus = false;
              _this.loadList();
              _this.$message({
                type: 'success',
                message: '操作成功!'
              });
            })
            .catch(err => {
              _this.editLoading = false;
              _this.loadList();
              _this.$message.error(err.msg);
            })
      }).catch(() => {
      });
    },
    // 删除
    remove(item) {
      const _this = this;
      if (_this.auth.adminPackageDelete) {
        _this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.delLoading = true;
          adminPackageDelete({id: item.id})
              .then(res => {
                _this.delLoading = false;
                _this.loadList();
                _this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
              })
              .catch(err => {
                _this.delLoading = false;
                _this.$message.error(err.msg);
              })
        }).catch(() => {
        });
      } else {
        _this.$message.error('没有操作权限');
      }
    },
    // 设置字段
    field(item) {
      const _this = this;
      _this.fieldLoading = true;
      adminPackageFieldDetail({id: item.id})
          .then(res => {
            _this.fieldLoading = false;
            _this.fieldStatus = true;
            _this.fieldForm.id = res.package.id;
            _this.fieldSelect = res.select;
            _this.fieldOptionalList = res.optionalList;
          })
          .catch(err => {
            _this.fieldLoading = false;
            _this.$message.error(err.msg);
          })
    },
    fieldFinish() {
      const _this = this;
      _this.fieldLoading = true;
      _this.fieldForm.field = _this.fieldSelect.join(',');
      adminPackageField(_this.fieldForm)
          .then(res => {
            _this.fieldLoading = false;
            _this.fieldStatus = false;
            _this.loadList();
            _this.$message({
              type: 'success',
              message: '操作成功!'
            });
          })
          .catch(err => {
            _this.fieldLoading = false;
            _this.$message.error(err.msg);
          })
    },
  },
};
</script>

<style scoped>
.custom-tree-node {
  display: flex;
  justify-content: space-between;
}

.custom-tree-node-name {
  width: 800px;
}
</style>