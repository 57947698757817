<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">订单信息</h3>
            <div class="box-tools pull-right">
              <el-button v-if="auth.adminOrderCreate" @click="append">新增</el-button>
            </div>
          </div>
          <div class="box-body no-padding" v-if="auth.adminOrderList">
            <div style="padding-top: 20px;padding-left: 20px;">
              <el-form :inline="true">
                <el-form-item label="营销案">
                  <el-select
                      style="width: 400px;"
                      v-model="query.packageId"
                      filterable
                      remote
                      :remote-method="loadPackage"
                      placeholder="输入名称或营销案编码搜索"
                  >
                    <el-option label="全部" :value="0"></el-option>
                    <div v-for="(type, a) in listPackageList" :key="a">
                      <div v-for="(grade, b) in type.children" :key="b">
                        <div v-if="grade.children.length > 0" style="padding: 10px 20px;color: #999999;">{{
                            type.name
                          }}/{{ grade.name }}
                        </div>
                        <el-option
                            v-for="(item, i) in grade.children"
                            :key="i"
                            :label="item.name + '('+item.promoCode+')'"
                            :value="item.id"
                        >
                        </el-option>
                      </div>
                    </div>
                  </el-select>
                </el-form-item>
                <el-form-item label="提单人">
                  <el-select
                      v-model="query.userId"
                      filterable
                      remote
                      placeholder="输入名称搜索"
                      :remote-method="loadPeople"
                  >
                    <el-option label="全部" :value="0"></el-option>
                    <el-option
                        v-for="(item, i) in listPeopleList"
                        :key="i"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="受理号码">
                  <el-input v-model="query.acceptCode" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="来源">
                  <el-select v-model="query.fromId" placeholder="请选择">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option
                        v-for="(item, i) in listFromList"
                        :key="i"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="受理时间">
                  <el-date-picker
                      v-model="queryTime"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      align="right"
                      @change="pickerSelect"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="生效时间">
                  <el-select v-model="query.beginType" placeholder="请选择">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option
                        v-for="(item, i) in listBeginTypeList"
                        :key="i"
                        :label="item.name"
                        :value="item.beginType"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="客户套餐">
                  <el-input v-model="query.packageCurrent" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="转后套餐">
                  <el-input v-model="query.packageChange" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                  <el-select v-model="query.statusId" placeholder="请选择">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option
                        v-for="(item, i) in listStatusList"
                        :key="i"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="排序">
                  <el-select v-model="query.sort" placeholder="请选择">
                    <el-option label="默认排序" :value="0"></el-option>
                    <el-option label="按受理时间倒序" :value="1"></el-option>
                    <el-option label="按受理时间升序" :value="2"></el-option>
                    <el-option label="按状态升序" :value="3"></el-option>
                    <el-option label="按状态倒序" :value="4"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="querySelect">查询</el-button>
                  <el-button type="primary" v-if="auth.adminOrderExport" @click="queryExport">导出</el-button>
                  <span style="margin-left: 20px">总共{{total}}条</span>
                </el-form-item>
              </el-form>
            </div>

            <el-table
                :data="list"
                border
                v-loading="listLoading"
                style="width: 100%"
            >
              <el-table-column
                  prop="acceptCode"
                  label="受理号码"
                  width="120"
              >
              </el-table-column>
              <el-table-column
                  prop="packageTypeName"
                  label="业务类型"
                  width="120"
              >
              </el-table-column>
              <el-table-column
                  prop="packageGradeName"
                  label="业务档次"
                  width="120"
              >
              </el-table-column>
              <el-table-column
                  prop="packageName"
                  label="营销案"
                  width="120"
              >
              </el-table-column>
              <el-table-column
                  prop="packagePromoCode"
                  label="项目营销编码"
                  width="120"
              >
              </el-table-column>
              <el-table-column
                  prop="acceptDate"
                  label="受理时间"
                  width="100"
              >
              </el-table-column>
              <el-table-column
                  prop="beginTypeName"
                  label="生效时间"
                  width="80"
              >
              </el-table-column>
              <el-table-column
                  prop="fromName"
                  label="业务来源"
                  width="80"
              >
              </el-table-column>
              <el-table-column
                  prop="typeName"
                  label="订单类型"
                  width="80"
              >
              </el-table-column>
              <el-table-column
                  prop="userName"
                  label="提单人员"
                  width="80"
              >
              </el-table-column>
              <el-table-column
                  v-if="auth.adminOrderStatus"
                  label="业务状态"
                  width="120"
              >
                <template slot-scope="scope">
                  <div>
                    <el-select v-model="scope.row.statusId" @change="editStatus(scope.row)" placeholder="请选择">
                      <el-option
                          v-for="(item, i) in listStatusList"
                          :key="i"
                          :label="item.name"
                          :value="item.id">
                        <span :style="'color: '+item.color">{{ item.name }}</span>
                      </el-option>
                    </el-select>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  v-else
                  prop="statusName"
                  label="业务状态"
                  width="120"
              >
              </el-table-column>
              <el-table-column
                  prop="userOrgName"
                  label="受理机构"
                  width="120"
              >
              </el-table-column>
              <el-table-column
                  prop="acceptJobCode"
                  label="受理工号"
                  width="120"
              >
              </el-table-column>
              <el-table-column
                  prop="packageCurrent"
                  label="客户套餐"
                  width="120"
              >
              </el-table-column>
              <el-table-column
                  prop="packageDiffer"
                  label="套餐差价"
                  width="120"
              >
              </el-table-column>
              <el-table-column
                  prop="packageChange"
                  label="转后套餐"
                  width="120"
              >
              </el-table-column>
              <el-table-column
                  prop="remark"
                  label="备注信息"
                  width="200"
              >
              </el-table-column>
              <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                  <el-button
                      type="text"
                      size="small"
                      v-if="auth.adminOrderUpdate"
                      @click="edit(scope.row)"
                  >
                    编辑
                  </el-button>
                  <el-button
                      type="text"
                      size="small"
                      v-if="auth.adminOrderDelete"
                      @click="remove(scope.row)"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div style="padding: 10px">
              <el-pagination
                  background
                  layout="total, prev, pager, next"
                  :total="total"
                  :page-size="query.size"
                  :current-page="query.page"
                  @current-change="pageChange"
              >
              </el-pagination>
            </div>
          </div>
          <div class="box-body" v-if="!auth.adminOrderList">
            <div>没有权限</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新建 -->
    <div v-if="auth.adminOrderCreate">
      <el-drawer
          title="新建"
          size="80%"
          :visible.sync="addOpen"
          :append-to-body="true"
          :wrapperClosable="true">
        <el-row>
          <el-col :span="12">
            <el-form ref="orderAddForm" label-width="140px">
              <el-form-item label="选择营销案">
                <el-select
                    v-model="addForm.packageId"
                    filterable
                    remote
                    :clearable="true"
                    :remote-method="appendPackageSelect"
                    placeholder="输入名称或营销案编码搜索"
                    style="width: 600px;"
                    @change="appendCodeSelect"
                >
                  <div v-for="(type, a) in addPackageList" :key="a">
                    <div v-for="(grade, b) in type.children" :key="b">
                      <div v-if="grade.children.length > 0" style="padding: 10px 20px;color: #999999;">
                        {{ type.name }}/{{ grade.name }}
                      </div>
                      <el-option
                          v-for="(item, i) in grade.children"
                          :key="i"
                          :label="item.name + '('+item.promoCode+')'"
                          :value="item.id"
                      >
                      </el-option>
                    </div>
                  </div>
                </el-select>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <div v-if="addParamList.length > 0">
          <el-form ref="orderAddForm" :model="addForm" label-width="140px">
            <el-row>
              <el-col :span="12">
                <div v-for="(item, i) in addParamList" :key="i">
                  <div v-if="item.inputType === 3">
                    <div v-if="item.isRequired">
                      <el-form-item
                          :label="item.name"
                          :prop="item.field"
                          :rules="[{ required: true, message: '请选择' + item.name, trigger: 'blur' }]"
                      >
                        <el-select v-model="addForm[item.field]" placeholder="请选择">
                          <el-option
                              v-for="(param, a) in item.inputSelect"
                              :key="a"
                              :label="param.name"
                              :value="param.id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div v-else>
                      <el-form-item :label="item.name">
                        <el-select v-model="addForm[item.field]" placeholder="请选择">
                          <el-option
                              v-for="(param, a) in item.inputSelect"
                              :key="a"
                              :label="param.name"
                              :value="param.id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div v-else-if="item.inputType === 2">
                    <div v-if="item.isRequired">
                      <el-form-item
                          :label="item.name"
                          :prop="item.field"
                          :rules="[{ required: true, message: '请选择' + item.name, trigger: 'blur' }]"
                      >
                        <el-date-picker
                            v-model="addForm[item.field]"
                            type="date"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            placeholder="选择时间">
                        </el-date-picker>
                      </el-form-item>
                    </div>
                    <div v-else>
                      <el-form-item :label="item.name">
                        <el-date-picker
                            v-model="addForm[item.field]"
                            type="date"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            placeholder="选择时间">
                        </el-date-picker>
                      </el-form-item>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="item.isRequired">
                      <el-form-item
                          :label="item.name"
                          :prop="item.field"
                          :rules="[{ required: true, message: '请输入' + item.name, trigger: 'blur' }]"
                      >
                        <el-input :type="item.isTextarea ? 'textarea':'text'" v-model="addForm[item.field]"
                                  :placeholder="'请输入' + item.name"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>
                      <el-form-item :label="item.name">
                        <el-input :type="item.isTextarea ? 'textarea':'text'" v-model="addForm[item.field]"
                                  :placeholder="'请输入' + item.name"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <el-form-item>
                  <el-button type="primary" @click="appendFinish">保存</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-drawer>
    </div>

    <!-- 修改 -->
    <div v-if="auth.adminOrderUpdate">
      <el-drawer
          title="修改"
          size="80%"
          :visible.sync="editOpen"
          :append-to-body="true"
          :wrapperClosable="true"
      >
        <div v-if="editParamList.length > 0">
          <el-form ref="orderEditForm" :model="editForm" label-width="140px">
            <el-row>
              <el-col :span="12">
                <div v-for="(item, i) in editParamList" :key="i">
                  <div v-if="item.inputType === 3">
                    <div v-if="item.isRequired">
                      <el-form-item
                          :label="item.name"
                          :prop="item.field"
                          :rules="[{ required: true, message: '请选择' + item.name, trigger: 'blur' }]"
                      >
                        <el-select v-model="editForm[item.field]" placeholder="请选择">
                          <el-option
                              v-for="(param, a) in item.inputSelect"
                              :key="a"
                              :label="param.name"
                              :value="param.id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div v-else>
                      <el-form-item :label="item.name">
                        <el-select v-model="editForm[item.field]" placeholder="请选择">
                          <el-option
                              v-for="(param, a) in item.inputSelect"
                              :key="a"
                              :label="param.name"
                              :value="param.id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div v-else-if="item.inputType === 2">
                    <div v-if="item.isRequired">
                      <el-form-item
                          :label="item.name"
                          :prop="item.field"
                          :rules="[{ required: true, message: '请选择' + item.name, trigger: 'blur' }]"
                      >
                        <el-date-picker
                            v-model="editForm[item.field]"
                            type="date"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            placeholder="选择时间">
                        </el-date-picker>
                      </el-form-item>
                    </div>
                    <div v-else>
                      <el-form-item :label="item.name">
                        <el-date-picker
                            v-model="editForm[item.field]"
                            type="date"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            placeholder="选择时间">
                        </el-date-picker>
                      </el-form-item>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="item.isRequired">
                      <el-form-item
                          :label="item.name"
                          :prop="item.field"
                          :rules="[{ required: true, message: '请输入' + item.name, trigger: 'blur' }]"
                      >
                        <el-input :type="item.isTextarea ? 'textarea':'text'" v-model="editForm[item.field]"
                                  :placeholder="'请输入' + item.name"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>
                      <el-form-item :label="item.name">
                        <el-input :type="item.isTextarea ? 'textarea':'text'" v-model="editForm[item.field]"
                                  :placeholder="'请输入' + item.name"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <el-form-item>
                  <el-button type="primary" @click="editFinish">保存</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import qs from "qs";
import {
  adminOrderListPeople,
  adminOrderListSelect,
  adminOrderListPackage,
  adminOrderList,
  adminOrderStatus,
  adminOrderDelete,
  adminOrderExport,
  adminOrderUpdateDetail,
  adminOrderUpdate,
  adminOrderCreate,
  adminOrderCreateDetail,
  adminOrderCreatePackage, adminRoleUpdate,
} from "../../api"

export default {
  components: {},
  data() {
    return {
      user: {},
      auth: {},
      params: {},
      queryTime: [],
      query: {
        packageId: 0,
        userId: 0,
        statusId: 0,
        acceptCode: '',
        acceptStart: '',
        acceptEnd: '',
        fromId: 0,
        beginType: 0,
        packageCurrent: '',
        packageChange: '',
        sort: 0,
        page: 1,
        size: 10,
      },
      listPeopleList: [],
      listPackageList: [],
      listStatusList: [],
      listFromList: [],
      listBeginTypeList: [],
      list: [],
      total: 0,
      listLoading: false,
      // 删除
      delLoading: false,
      // 更新状态
      statusForm: {
        id: '',
        statusId: '',
      },
      // 新增
      addOpen: false,
      addLoading: false,
      addParamList: [],
      addPackageList: [],
      addForm: {
        packageId: '',
        acceptCode: "",
        acceptDate: "",
        beginType: "",
        fromId: "",
        packageChange: "",
        packageCurrent: "",
        packageDiffer: "",
        remark: "",
        statusId: "",
      },
      // 修改
      editOpen: false,
      editLoading: false,
      editParamList: [],
      editForm: {
        id: '',
        acceptCode: '',
        acceptDate: '',
        beginType: '',
        fromId: '',
        packageChange: '',
        packageCurrent: '',
        packageDiffer: '',
        remark: '',
        statusId: '',
      },
    };
  },
  computed: mapState({
    config: (state) => state.config,
  }),
  mounted() {
    const _this = this;
    _this.query = Object.assign(_this.query, _this.$route.query);
    _this.query.page = parseInt(_this.query.page);
    _this.query.size = parseInt(_this.query.size);
    _this.query.packageId = parseInt(_this.query.packageId);
    _this.query.userId = parseInt(_this.query.userId);
    _this.query.statusId = parseInt(_this.query.statusId);
    _this.query.fromId = parseInt(_this.query.fromId);
    _this.query.beginType = parseInt(_this.query.beginType);
    _this.query.sort = parseInt(_this.query.sort);
    if (_this.query.acceptStart !== '') {
      _this.queryTime[0] = _this.query.acceptStart;
    }
    if (_this.query.acceptEnd !== '') {
      _this.queryTime[1] = _this.query.acceptEnd;
    }
    _this.params = Object.assign(_this.params, _this.$route.params);
    _this.loading = true;
    _this.authInit(_this).then((res) => {
      _this.user = res.user;
      _this.auth = res.auth;
      if (_this.auth.adminOrderList) {
        _this.loadSelect();
      }
    });
  },
  methods: {
    ...mapActions(["authInit"]),
    updateUrl() {
      const _this = this;
      const url = _this.$route.path + "?" + qs.stringify(_this.query);
      _this.$router.replace({path: url});
    },
    loadSelect() {
      const _this = this;
      _this.listLoading = true;
      adminOrderListSelect()
          .then(res => {
            _this.listStatusList = res.statusList;
            _this.listFromList = res.fromList;
            _this.listBeginTypeList = res.beginTypeList;
            _this.loadList();
          })
          .catch(err => {
            _this.listLoading = false;
            _this.$message.error(err.msg);
          })
    },
    // 获取列表
    loadList() {
      const _this = this;
      adminOrderList(_this.query)
          .then(res => {
            _this.listLoading = false;
            _this.list = res.list;
            _this.total = res.total;
            _this.loadPackage('');
            _this.loadPeople('');
            _this.updateUrl();
          })
          .catch(err => {
            _this.listLoading = false;
            _this.$message.error(err.msg);
          })
    },
    // 分页
    pageChange(page) {
      const _this = this;
      _this.query.page = parseInt(page);
      _this.loadList();
    },
    // 筛选
    querySelect() {
      const _this = this;
      _this.query.page = 1;
      _this.loadList();
    },
    // 选择时间
    pickerSelect() {
      const _this = this;
      if (_this.queryTime && _this.queryTime.length > 0) {
        _this.query.acceptStart = _this.queryTime[0];
        _this.query.acceptEnd = _this.queryTime[1];
      } else {
        _this.query.acceptStart = '';
        _this.query.acceptEnd = '';
      }
    },
    // 获取套餐
    loadPackage(keyword) {
      const _this = this;
      adminOrderListPackage({keyword: keyword})
          .then(res => {
            _this.listPackageList = res.packageList;
          })
          .catch(err => {
            _this.$message.error(err.msg);
          })
    },
    // 获取套餐
    loadPeople(keyword) {
      const _this = this;
      adminOrderListPeople({keyword: keyword, page: 1, size: 100})
          .then(res => {
            _this.listPeopleList = res.list;
          })
          .catch(err => {
            _this.$message.error(err.msg);
          })
    },
    // 导出
    queryExport() {
      const _this = this;
      _this.$confirm('是否导出当前所选?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        adminOrderExport(_this.query)
            .then(res => {
              if (res.url) {
                window.location.href = res.url;
              }
            })
            .catch(err => {
              _this.$message.error(err.msg);
            })
      }).catch(() => {
      });
    },
    // 删除
    remove(item) {
      const _this = this;
      if (_this.auth.adminOrderDelete) {
        _this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.delLoading = true;
          adminOrderDelete({id: item.id})
              .then(res => {
                _this.delLoading = false;
                _this.loadList();
                _this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
              })
              .catch(err => {
                _this.delLoading = false;
                _this.$message.error(err.msg);
              })
        }).catch(() => {
        });
      } else {
        _this.$message.error('没有操作权限');
      }
    },
    // 状态
    editStatus(item) {
      const _this = this;
      _this.statusForm.id = item.id;
      _this.statusForm.statusId = item.statusId;
      adminOrderStatus(_this.statusForm)
          .then(res => {
            _this.loadList();
            _this.$message({
              type: 'success',
              message: '操作成功!'
            });
          })
          .catch(err => {
            _this.loadList();
            _this.$message.error(err.msg);
          })
    },
    // 新增
    append() {
      const _this = this;
      _this.addOpen = true;
      _this.addParamList = [];
      _this.addForm = {
        packageId: '',
        acceptCode: "",
        acceptDate: "",
        beginType: "",
        fromId: "",
        packageChange: "",
        packageCurrent: "",
        packageDiffer: "",
        remark: "",
        statusId: "",
      };
      _this.appendPackageList('').then(packageList => {
        _this.addPackageList = packageList;
      })
    },
    appendPackageSelect(keyword) {
      const _this = this;
      _this.appendPackageList(keyword).then(packageList => {
        _this.addPackageList = packageList;
      })
    },
    appendPackageList(keyword) {
      const _this = this;
      return new Promise((resolve) => {
        _this.addLoading = false;
        adminOrderCreatePackage({keyword: keyword})
            .then(res => {
              _this.addLoading = false;
              resolve(res.packageList)
            })
            .catch(err => {
              _this.addLoading = false;
              _this.$message.error(err.msg);
            })
      })
    },
    appendCodeSelect() {
      const _this = this;
      _this.addLoading = false;
      if (_this.addForm.packageId !== '') {
        adminOrderCreateDetail({
          packageId: _this.addForm.packageId
        })
            .then(res => {
              _this.addLoading = false;
              _this.addForm = res.form;
              _this.addParamList = res.paramList;
            })
            .catch(err => {
              _this.addLoading = false;
              _this.$message.error(err.msg);
            })
      } else {
        _this.addParamList = [];
        _this.addForm = {
          packageId: '',
          acceptCode: "",
          acceptDate: "",
          beginType: "",
          fromId: "",
          packageChange: "",
          packageCurrent: "",
          packageDiffer: "",
          remark: "",
          statusId: "",
        };
      }
    },
    appendFinish() {
      const _this = this;
      _this.$refs.orderAddForm.validate((valid) => {
        if (valid) {
          _this.addLoading = false;
          adminOrderCreate(_this.addForm)
              .then(res => {
                _this.addLoading = false;
                _this.addOpen = false;
                _this.loadList();
                _this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
              })
              .catch(err => {
                _this.addLoading = false;
                _this.$message.error(err.msg);
              })
        } else {
          return false;
        }
      })
    },
    // 修改
    edit(item) {
      const _this = this;
      _this.editLoading = true;
      adminOrderUpdateDetail({id: item.id})
          .then(res => {
            _this.editLoading = false;
            _this.editForm = res.form;
            _this.editParamList = res.paramList;
            _this.editOpen = true;
          })
          .catch(err => {
            _this.editLoading = false;
            _this.$message.error(err.msg);
          })
    },
    editFinish() {
      const _this = this;
      _this.$refs.orderEditForm.validate((valid) => {
        if (valid) {
          _this.editLoading = true;
          adminOrderUpdate(_this.editForm)
              .then(res => {
                _this.editLoading = false;
                _this.editOpen = false;
                _this.loadList();
                _this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
              })
              .catch(err => {
                _this.editLoading = false;
                _this.$message.error(err.msg);
              })
        } else {
          return false;
        }
      })
    }
  },
};
</script>

<style scoped>
.custom-tree-node {
  display: flex;
  justify-content: space-between;
}

.custom-tree-node-name {
  width: 400px;
}
</style>