<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">账号列表信息</h3>
            <div class="box-tools pull-right">
              <el-button v-if="auth.adminPeopleCreate" @click="append">新增</el-button>
            </div>
          </div>
          <div class="box-body no-padding" v-if="auth.adminPeopleList">
            <div style="padding-top: 20px;padding-left: 20px;">
              <el-form :inline="true">
                <el-form-item label="组织">
                  <el-select v-model="query.orgId" filterable :clearable="true" placeholder="请选择">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option v-for="(item, i) in listOptionalOrgList" :key="i" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="角色">
                  <el-select v-model="query.roleId" filterable :clearable="true" placeholder="请选择">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option v-for="(item, i) in listOptionalRoleList" :key="i" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="姓名">
                  <el-input v-model="query.name" placeholder="姓名搜索"></el-input>
                </el-form-item>
                <el-form-item label="工号">
                  <el-input v-model="query.jobCode" placeholder="工号搜索"></el-input>
                </el-form-item>
                <el-form-item label="受理工号">
                  <el-input v-model="query.acceptJobCode" placeholder="受理工号搜索"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="querySelect">查询</el-button>
                </el-form-item>
              </el-form>
            </div>

            <el-table
                :data="peopleList"
                border
                v-loading="peopleListLoading"
                style="width: 100%">
              <el-table-column
                  fixed
                  prop="account"
                  label="账号"
                  width="150"
              >
              </el-table-column>
              <el-table-column
                  prop="name"
                  label="姓名"
                  width="120"
              >
              </el-table-column>
              <el-table-column
                  prop="jobCode"
                  label="工号"
              >
              </el-table-column>
              <el-table-column
                  prop="acceptJobCode"
                  label="受理工号"
              >
              </el-table-column>
              <el-table-column
                  prop="orgName"
                  label="所属组织"
              >
              </el-table-column>
              <el-table-column
                  prop="roleName"
                  label="角色"
              >
              </el-table-column>
              <el-table-column
                  label="操作"
                  width="200"
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.is_child">
                    <el-button
                        type="text"
                        size="small"
                        v-if="!scope.row.is_self && auth.adminPeopleUpdate"
                        @click="edit(scope.row)"
                    >
                      编辑
                    </el-button>
                    <el-button
                        type="text"
                        size="small"
                        v-if="!scope.row.is_self && auth.adminPeopleUpdate"
                        @click="editPwd(scope.row)"
                    >
                      重设密码
                    </el-button>
                    <el-button
                        type="text"
                        size="small"
                        v-if="!scope.row.is_self && auth.adminPeopleDelete"
                        @click="remove(scope.row)"
                    >
                      删除
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div style="padding: 10px">
              <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="peopleTotal"
                  :page-size="query.size"
                  :current-page="query.page"
                  @current-change="pageChange"
              >
              </el-pagination>
            </div>
          </div>
          <div class="box-body" v-if="!auth.adminPeopleList">
            <div>没有权限</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新增 -->
    <div v-if="auth.adminPeopleCreate">
      <el-dialog
          title="新增账号"
          :modal-append-to-body="true"
          :append-to-body="true"
          :close-on-click-modal="false"
          :visible.sync="addStatus"
          v-if="addStatus"
      >
        <el-form ref="peopleAddForm" label-width="80px" :model="addForm">
          <el-form-item
              label="登录账号"
              prop="account"
              :rules="[{ required: true, message: '请输入登录账号', trigger: 'blur' }]"
          >
            <el-input v-model="addForm.account" placeholder="请输入登录账号"></el-input>
          </el-form-item>
          <el-form-item
              label="登录密码"
              prop="password"
              :rules="[{ required: true, message: '请输入登录密码', trigger: 'blur' }]"
          >
            <el-input v-model="addForm.password" placeholder="请输入登录密码"></el-input>
          </el-form-item>
          <el-form-item
              label="用户姓名"
              prop="name"
              :rules="[{ required: true, message: '请输入用户姓名', trigger: 'blur' }]"
          >
            <el-input v-model="addForm.name" placeholder="请输入用户姓名"></el-input>
          </el-form-item>
          <el-form-item
              label="所属组织"
              prop="orgId"
              :rules="[{ required: true, message: '请选择所属组织', trigger: 'blur' }]"
          >
            <el-select v-model="addForm.orgId" filterable placeholder="请选择所属组织">
              <el-option v-for="(item, i) in addOptionalOrgList" :key="i" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
              label="角色权限"
              prop="roleId"
              :rules="[{ required: true, message: '请选择角色', trigger: 'blur' }]"
          >
            <el-select v-model="addForm.roleId" filterable placeholder="请选择角色">
              <el-option v-for="(item, i) in addOptionalRoleList" :key="i" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
              label="工号"
              prop="name"
          >
            <el-input v-model="addForm.jobCode" placeholder="请输入工号"></el-input>
          </el-form-item>
          <el-form-item
              label="受理工号"
              prop="name"
          >
            <el-input v-model="addForm.acceptJobCode" placeholder="请输入受理工号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="appendFinish">立即创建</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>

    <!-- 修改 -->
    <div v-if="auth.adminPeopleUpdate">
      <el-dialog
          title="修改账号信息"
          :modal-append-to-body="true"
          :append-to-body="true"
          :close-on-click-modal="false"
          :visible.sync="editStatus"
          v-if="editStatus"
      >
        <el-form ref="peopleEditForm" label-width="80px" :model="editForm">
          <el-form-item
              label="账号名称"
              prop="name"
              :rules="[{ required: true, message: '请输入账号名称', trigger: 'blur' }]"
          >
            <el-input v-model="editForm.name"></el-input>
          </el-form-item>
          <el-form-item
              label="所属组织"
              prop="orgId"
              :rules="[{ required: true, message: '请选择所属组织', trigger: 'blur' }]"
          >
            <el-select v-model="editForm.orgId" filterable placeholder="请选择所属组织">
              <el-option v-for="(item, i) in editOptionalOrgList" :key="i" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
              label="角色权限"
              prop="roleId"
              :rules="[{ required: true, message: '请选择角色', trigger: 'blur' }]"
          >
            <el-select v-model="editForm.roleId" filterable placeholder="请选择角色">
              <el-option v-for="(item, i) in editOptionalRoleList" :key="i" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
              label="工号"
              prop="name"
          >
            <el-input v-model="editForm.jobCode" placeholder="请输入工号"></el-input>
          </el-form-item>
          <el-form-item
              label="受理工号"
              prop="name"
          >
            <el-input v-model="editForm.acceptJobCode" placeholder="请输入受理工号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="editFinish">保存修改</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>


      <el-dialog
          title="重设账号密码"
          :modal-append-to-body="true"
          :append-to-body="true"
          :close-on-click-modal="false"
          :visible.sync="editPwdStatus"
          v-if="editPwdStatus"
      >
        <el-form ref="peopleEditPwdForm" label-width="120px" :model="editPwdForm">
          <el-form-item
              label="账号密码"
              prop="password"
              :rules="[{ required: true, message: '请输入账号密码', trigger: 'blur' }]"
          >
            <el-input v-model="editPwdForm.password" placeholder="请输入账号密码"></el-input>
          </el-form-item>
          <el-form-item
              label="账号密码"
              prop="passwordAgain"
              :rules="[{ required: true, message: '请再次输入账号密码', trigger: 'blur' }]"
          >
            <el-input v-model="editPwdForm.passwordAgain" placeholder="请再次输入账号密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="editPwdFinish">保存修改</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import qs from "qs";
import {
  adminPeopleListSelect,
  adminPeopleList,
  adminPeopleUpdateDetail,
  adminPeopleUpdate,
  adminPeopleCreateDetail,
  adminPeopleCreate,
  adminPeopleDelete, adminPeopleUpdatePassword,
} from "../../api"

export default {
  components: {},
  data() {
    return {
      user: {},
      auth: {},
      params: {},
      query: {
        orgId: 0,
        roleId: 0,
        name: '',
        jobCode: '',
        acceptJobCode: '',
        page: 1,
        size: 10,
      },
      listOptionalOrgList: [],
      listOptionalRoleList: [],
      peopleList: [],
      peopleTotal: 0,
      peopleListLoading: false,
      // 新增
      addOptionalRoleList: [],
      addOptionalOrgList: [],
      addStatus: false,
      addLoading: false,
      addForm: {
        account: '',
        password: '',
        name: '',
        orgId: '',
        roleId: '',
        jobCode: '',
        acceptJobCode: '',
      },
      // 修改
      editOptionalRoleList: [],
      editOptionalOrgList: [],
      editStatus: false,
      editLoading: false,
      editForm: {
        id: '',
        name: '',
        orgId: 0,
        roleId: 0,
        jobCode: '',
        acceptJobCode: '',
      },
      // 删除
      delLoading: false,
      // 密码
      editPwdStatus: false,
      editPwdLoading: false,
      editPwdForm: {
        id: '',
        password: '',
        passwordAgain: '',
      },
    };
  },
  computed: mapState({
    config: (state) => state.config,
  }),
  mounted() {
    const _this = this;
    _this.query = Object.assign(_this.query, _this.$route.query);
    _this.query.page = parseInt(_this.query.page);
    _this.query.size = parseInt(_this.query.size);
    _this.query.orgId = parseInt(_this.query.orgId);
    _this.query.roleId = parseInt(_this.query.roleId);
    _this.params = Object.assign(_this.params, _this.$route.params);
    _this.loading = true;
    _this.authInit(_this).then((res) => {
      _this.user = res.user;
      _this.auth = res.auth;
      if (_this.auth.adminPeopleList) {
        _this.loadSelect();
      }
    });
  },
  methods: {
    ...mapActions(["authInit"]),
    updateUrl() {
      const _this = this;
      const url = _this.$route.path + "?" + qs.stringify(_this.query);
      _this.$router.replace({path: url});
    },
    loadSelect() {
      const _this = this;
      _this.peopleListLoading = true;
      adminPeopleListSelect()
          .then(res => {
            _this.listOptionalOrgList = res.orgList;
            _this.listOptionalRoleList = res.roleList;
            _this.loadList();
          })
          .catch(err => {
            _this.peopleListLoading = false;
            _this.$message.error(err.msg);
          })
    },
    // 获取列表
    loadList() {
      const _this = this;
      adminPeopleList(_this.query)
          .then(res => {
            _this.peopleListLoading = false;
            _this.peopleList = res.list;
            _this.peopleTotal = res.total;
            _this.updateUrl();
          })
          .catch(err => {
            _this.peopleListLoading = false;
            _this.$message.error(err.msg);
          })
    },
    // 分页
    pageChange(page) {
      const _this = this;
      _this.query.page = parseInt(page);
      _this.loadList();
    },
    // 筛选
    querySelect() {
      const _this = this;
      _this.query.page = 1;
      _this.loadList();
    },
    // 新增
    append() {
      const _this = this;
      _this.addLoading = true;
      adminPeopleCreateDetail()
          .then(res => {
            _this.addLoading = false;
            _this.addStatus = true;
            _this.addOptionalOrgList = res.optionalOrgList;
            _this.addOptionalRoleList = res.optionalRoleList;
          })
          .catch(err => {
            _this.addLoading = false;
            _this.$message.error(err.msg);
          })
    },
    appendFinish() {
      const _this = this;
      _this.$refs.peopleAddForm.validate((valid) => {
        if (valid) {
          _this.addLoading = true;
          adminPeopleCreate(_this.addForm)
              .then(res => {
                _this.addLoading = false;
                _this.addStatus = false;
                _this.loadList();
                _this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
              })
              .catch(err => {
                _this.addLoading = false;
                _this.$message.error(err.msg);
              })
        } else {
          return false;
        }
      });
    },
    // 修改
    edit(item) {
      const _this = this;
      _this.editLoading = true;
      adminPeopleUpdateDetail({id: item.id})
          .then(res => {
            _this.editLoading = false;
            _this.editStatus = true;
            _this.editForm.id = res.people.id;
            _this.editForm.name = res.people.name;
            _this.editForm.orgId = res.people.orgId;
            _this.editForm.roleId = res.people.roleId;
            _this.editForm.jobCode = res.people.jobCode;
            _this.editForm.acceptJobCode = res.people.acceptJobCode;
            _this.editOptionalOrgList = res.optionalOrgList;
            _this.editOptionalRoleList = res.optionalRoleList;
          })
          .catch(err => {
            _this.editLoading = false;
            _this.$message.error(err.msg);
          })
    },
    editFinish() {
      const _this = this;
      _this.$refs.peopleEditForm.validate((valid) => {
        if (valid) {
          _this.editLoading = true;
          adminPeopleUpdate(_this.editForm)
              .then(res => {
                _this.editLoading = false;
                _this.editStatus = false;
                _this.loadList();
                _this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
              })
              .catch(err => {
                _this.editLoading = false;
                _this.$message.error(err.msg);
              })
        } else {
          return false;
        }
      })
    },
    // 删除
    remove(item) {
      const _this = this;
      if (_this.auth.adminPeopleDelete) {
        _this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.delLoading = true;
          adminPeopleDelete({id: item.id})
              .then(res => {
                _this.delLoading = false;
                _this.loadList();
                _this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
              })
              .catch(err => {
                _this.delLoading = false;
                _this.$message.error(err.msg);
              })
        }).catch(() => {
        });
      } else {
        _this.$message.error('没有操作权限');
      }
    },
    // 修改密码
    editPwd(item) {
      const _this = this;
      _this.editPwdLoading = true;
      adminPeopleUpdateDetail({id: item.id})
          .then(res => {
            _this.editPwdLoading = false;
            _this.editPwdStatus = true;
            _this.editPwdForm.id = res.people.id;
          })
          .catch(err => {
            _this.editPwdLoading = false;
            _this.$message.error(err.msg);
          })
    },
    editPwdFinish() {
      const _this = this;
      _this.$refs.peopleEditPwdForm.validate((valid) => {
        if (valid) {
          _this.editPwdLoading = true;
          adminPeopleUpdatePassword(_this.editPwdForm)
              .then(res => {
                _this.editPwdLoading = false;
                _this.editPwdStatus = false;
                _this.loadList();
                _this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
              })
              .catch(err => {
                _this.editPwdLoading = false;
                _this.$message.error(err.msg);
              })
        } else {
          return false;
        }
      })
    },

  },
};
</script>

<style scoped>
.custom-tree-node {
  display: flex;
  justify-content: space-between;
}

.custom-tree-node-name {
  width: 400px;
}
</style>