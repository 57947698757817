import Vue from "vue";
import VueLocalStorage from "vue-localstorage";
import ElementUI from 'element-ui';

import App from "./components/App.vue";
import router from "./router";
import store from "./store";
import * as api from "./api";

import "./styles/index.scss";

Vue.use(VueLocalStorage);
Vue.use(ElementUI, {size: 'small', zIndex: 3000});

Vue.prototype.$api = api;
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
