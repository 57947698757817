<template>
  <header class="main-header">
    <router-link to="/backend/bus/order" class="logo">
      <span class="logo-mini">
        <img class="logo-img" src="/logo.png" alt="logo">
      </span>
      <span class="logo-lg">
        <img class="logo-img" src="/logo.png" alt="logo">
        <span class="logo-name">江洪业务</span>
      </span>
    </router-link>
    <nav class="navbar navbar-static-top">
      <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button">
        <span class="sr-only"></span>
      </a>
      <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse">
          <i class="fa fa-bars"></i>
        </button>
      </div>

      <div class="collapse navbar-collapse pull-left" id="navbar-collapse">
        <ul class="nav navbar-nav">
          <li v-if="currentPage"><router-link :to="currentPage.url">{{ currentPage.title }}</router-link></li>
        </ul>
      </div>
      <div class="navbar-custom-menu">
        <ul class="nav navbar-nav">
          <li class="dropdown user user-menu">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
              <img src="/static/image/avatar.jpg" class="user-image" alt="User avatar">
              <span class="hidden-xs">{{ user.name }}</span>
            </a>
            <ul class="dropdown-menu">
              <li class="user-header">
                <img src="/static/image/avatar.jpg" class="img-circle" alt="User avatar">
                <p>
                  {{ user.name }}
                  <small>{{ user.roleName }}</small>
                </p>
              </li>
              <li class="user-footer">
                <div class="pull-left">
                  <a href="javascript:void(0);" @click="editPwdOpen" class="btn btn-default btn-flat">修改密码</a>
                </div>
                <div class="pull-right">
                  <a href="javascript:void(0);" @click="logout" class="btn btn-default btn-flat">退出登录</a>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>

    <div v-if="editPwdStatus">
      <el-dialog
          title="修改登录密码"
          :modal-append-to-body="true"
          :append-to-body="true"
          :close-on-click-modal="false"
          :visible.sync="editPwdStatus"
      >
        <el-form ref="userEditPwdForm" label-width="120px" :model="editPwdForm">
          <el-form-item
              label="账号密码"
              prop="password"
              :rules="[{ required: true, message: '请输入账号密码', trigger: 'blur' }]"
          >
            <el-input v-model="editPwdForm.password" placeholder="请输入账号密码"></el-input>
          </el-form-item>
          <el-form-item
              label="账号密码"
              prop="passwordAgain"
              :rules="[{ required: true, message: '请再次输入账号密码', trigger: 'blur' }]"
          >
            <el-input v-model="editPwdForm.passwordAgain" placeholder="请再次输入账号密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="editPwdFinish">保存修改</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";
import {adminAccountLogout, adminProfilePassword} from "../api"
export default {
  components: {},
  data() {
      return {
        editPwdStatus: false,
        editPwdLoading: false,
        editPwdForm: {
          password: '',
          passwordAgain: ''
        }
      }
  },
  computed: mapState({
    config: (state) => state.config,
    user: (state) => state.user.user,
    currentPage: (state) => state.currentPage,
  }),
  methods: {
    logout() {
      const _this = this;
      _this.$confirm('是否退出当前登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        adminAccountLogout()
          .then(() => {
            _this.$localStorage.remove("session");
            _this.$localStorage.remove("userInfo");
            _this.$router.replace({ path: '/backend/guest/login' })
          })
          .catch(() => {})
      }).catch(() => { });
    },
    editPwdOpen() {
      const _this = this;
      _this.editPwdStatus = true;
      _this.editPwdForm.password = '';
      _this.editPwdForm.passwordAgain = '';
    },
    editPwdFinish() {
      const _this = this;
      _this.$refs.userEditPwdForm.validate((valid) => {
        if (valid) {
          _this.editPwdStatus = false;
          _this.$confirm('是否确认修改当前密码?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            _this.editPwdLoading = true;
            adminProfilePassword(_this.editPwdForm)
                .then(res => {
                  _this.editPwdLoading = false;
                  _this.editPwdStatus = false;
                  _this.$message({
                    type: 'success',
                    message: '修改成功!'
                  });
                })
                .catch(err => {
                  _this.editPwdLoading = false;
                  _this.$message.error(err.msg);
                })
          }).catch(() => { });
        } else {
          return false;
        }
      })
    },
  }
}
</script>

<style scoped>
.main-header .logo .logo-lg {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.main-header .logo .logo-lg img.logo-img {
  width: 36px;
  height: 36px;
  max-width: 36px;
  margin: 0;
}

.main-header .logo .logo-lg .logo-name {
  padding-left: 10px;
}

.main-header .logo .logo-mini img.logo-img {
  width: 36px;
  height: 36px;
  max-width: 36px;
}
</style>
