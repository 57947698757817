<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">组织架构信息(可拖动改动层级结构)</h3>
            <div class="box-tools pull-right"></div>
          </div>
          <div class="box-body" v-if="auth.adminOrgList">
            <el-tree
                v-loading="orgListLoading"
                :data="orgList"
                :props="defaultProps"
                :expand-on-click-node="false"
                default-expand-all
                @node-drop="handleDrop"
                draggable
            >
              <div class="custom-tree-node" slot-scope="{ node, data }">
                <div class="custom-tree-node-name">{{ data.name }}</div>
                <div>
                  <el-button
                      v-if="auth.adminOrgCreate"
                      type="text"
                      size="mini"
                      :disabled="addLoading"
                      @click="append(data)"
                  >
                    新增下级
                  </el-button>
                  <el-button
                      type="text"
                      size="mini"
                      v-if="!data.is_top && auth.adminOrgUpdate"
                      :disabled="editLoading"
                      @click="edit(data)"
                  >
                    修改
                  </el-button>
                  <el-button
                      type="text"
                      size="mini"
                      v-if="!data.is_top && auth.adminOrgSee"
                      :disabled="perLoading"
                      @click="permission(data)"
                  >
                    设置组织可看权限
                  </el-button>
                  <el-button
                      type="text"
                      size="mini"
                      v-if="data.is_del && auth.adminOrgDelete"
                      :disabled="delLoading"
                      @click="remove(data)"
                  >
                    删除
                  </el-button>
                </div>
              </div>
            </el-tree>
          </div>
          <div class="box-body" v-if="!auth.adminOrgList">
            <div>没有权限</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新增 -->
    <div v-if="auth.adminOrgCreate">
      <el-dialog
          title="新增组织"
          :modal-append-to-body="true"
          :append-to-body="true"
          :close-on-click-modal="false"
          :visible.sync="addStatus"
          v-if="addStatus"
      >
        <el-form ref="orgAddForm" label-width="80px" :model="addForm">
          <el-form-item
              label="组织名称"
              prop="name"
              :rules="[{ required: true, message: '请输入组织名称', trigger: 'blur' }]"
          >
            <el-input v-model="addForm.name" placeholder="请输入组织名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="appendFinish">立即创建</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>

    <!-- 修改 -->
    <div v-if="auth.adminOrgUpdate">
      <el-dialog
          title="修改组织信息"
          :modal-append-to-body="true"
          :append-to-body="true"
          :close-on-click-modal="false"
          :visible.sync="editStatus"
          v-if="editStatus"
      >
        <el-form ref="orgEditForm" label-width="80px" :model="editForm">
          <el-form-item
              label="组织名称"
              prop="name"
              :rules="[{ required: true, message: '请输入组织名称', trigger: 'blur' }]"
          >
            <el-input v-model="editForm.name" placeholder="请输入组织名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="editFinish">保存修改</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>

    <!-- 权限 -->
    <div v-if="auth.adminOrgSee">
      <el-drawer
          title="修改组织可看权限（选中后可以看选中的组织对应的人员订单数据，不选则只能看自己数据）"
          size="80%"
          :visible.sync="perStatus"
          :append-to-body="true"
          :wrapperClosable="true">
        <el-form ref="form" label-width="120px">
          <el-form-item label="选择可看组织">
            <el-tree
                ref="permission"
                :data="optionalOrgList"
                show-checkbox
                node-key="id"
                default-expand-all
                :expand-on-click-node="false"
                :default-checked-keys="select"
                :check-strictly="true"
                :props="perProps">
            </el-tree>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="permissionFinish">保存设置</el-button>
          </el-form-item>
        </el-form>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import qs from "qs";
import {
  adminOrgDelete,
  adminOrgList,
  adminOrgUpdateDetail,
  adminOrgUpdate,
  adminOrgCreate,
  adminOrgSeeDetail,
  adminOrgSee
} from "../../api"

export default {
  components: {},
  data() {
    return {
      user: {},
      auth: {},
      params: {},
      query: {},
      orgList: [],
      orgListLoading: false,
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      // 新增
      addStatus: false,
      addLoading: false,
      addForm: {
        parentId: 0,
        name: ''
      },
      // 修改
      optionalList: [],
      editStatus: false,
      editLoading: false,
      editForm: {
        id: '',
        parentId: 0,
        name: ''
      },
      // 删除
      delLoading: false,
      // 权限
      select: [],
      optionalOrgList: [],
      perStatus: false,
      perLoading: false,
      perForm: {
        id: '',
        see: '',
      },
      perProps: {
        children: 'children',
        label: 'name',
      }
    };
  },
  computed: mapState({
    config: (state) => state.config,
  }),
  mounted() {
    const _this = this;
    _this.query = Object.assign(_this.query, _this.$route.query);
    _this.params = Object.assign(_this.params, _this.$route.params);
    _this.loading = true;
    _this.authInit(_this).then((res) => {
      _this.user = res.user;
      _this.auth = res.auth;
      if (_this.auth.adminOrgList) {
        _this.loadList();
      }
    });
  },
  methods: {
    ...mapActions(["authInit"]),
    updateUrl() {
      const _this = this;
      const url = _this.$route.path + "?" + qs.stringify(_this.query);
      _this.$router.replace({path: url});
    },
    // 获取列表
    loadList() {
      const _this = this;
      _this.orgListLoading = true;
      adminOrgList(_this.query)
          .then(res => {
            _this.orgListLoading = false;
            _this.orgList = res.orgList;
            _this.updateUrl();
          })
          .catch(err => {
            _this.orgListLoading = false;
            _this.$message.error(err.msg);
          })
    },
    // 新增
    append(item) {
      const _this = this;
      _this.addForm.parentId = item.id;
      _this.addForm.name = '';
      _this.addStatus = true;
    },
    appendFinish() {
      const _this = this;
      _this.$refs.orgAddForm.validate((valid) => {
        if (valid) {
          _this.addLoading = true;
          adminOrgCreate(_this.addForm)
              .then(res => {
                _this.addLoading = false;
                _this.addStatus = false;
                _this.loadList();
                _this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
              })
              .catch(err => {
                _this.addLoading = false;
                _this.$message.error(err.msg);
              })
        } else {
          return false;
        }
      });
    },
    // 修改
    edit(item) {
      const _this = this;
      _this.editLoading = true;
      adminOrgUpdateDetail({id: item.id})
          .then(res => {
            _this.editLoading = false;
            _this.editStatus = true;
            _this.editForm.id = res.org.id;
            _this.editForm.parentId = res.org.parentId;
            _this.editForm.name = res.org.name;
            _this.optionalList = res.optionalList;
          })
          .catch(err => {
            _this.editLoading = false;
            _this.$message.error(err.msg);
          })
    },
    editFinish() {
      const _this = this;
      _this.$refs.orgEditForm.validate((valid) => {
        if (valid) {
          _this.editLoading = true;
          adminOrgUpdate(_this.editForm)
              .then(res => {
                _this.editLoading = false;
                _this.editStatus = false;
                _this.loadList();
                _this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
              })
              .catch(err => {
                _this.editLoading = false;
                _this.$message.error(err.msg);
              })
        } else {
          return false;
        }
      });
    },
    handleDrop(raw, to, type) {
      const _this = this;
      _this.$confirm('是否执行当前拖动?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.editLoading = true;
        _this.editForm.id = raw.data.id;
        _this.editForm.name = raw.data.name;
        if (type === 'inner') {
          _this.editForm.parentId = to.data.id;
        } else {
          _this.editForm.parentId = to.data.parentId;
        }
        adminOrgUpdate(_this.editForm)
            .then(res => {
              _this.editLoading = false;
              _this.editStatus = false;
              _this.loadList();
              _this.$message({
                type: 'success',
                message: '操作成功!'
              });
            })
            .catch(err => {
              _this.editLoading = false;
              _this.$message.error(err.msg);
              _this.loadList();
            })
      }).catch(() => {
      });
    },
    // 删除
    remove(item) {
      const _this = this;
      if (_this.auth.adminOrgDelete) {
        _this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.delLoading = true;
          adminOrgDelete({id: item.id})
              .then(res => {
                _this.delLoading = false;
                _this.loadList();
                _this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
              })
              .catch(err => {
                _this.delLoading = false;
                _this.$message.error(err.msg);
              })
        }).catch(() => {
        });
      } else {
        _this.$message.error('没有操作权限');
      }
    },
    // 权限
    permission(item) {
      const _this = this;
      _this.perLoading = true;
      adminOrgSeeDetail({id: item.id})
          .then(res => {
            _this.perLoading = false;
            _this.perStatus = true;
            _this.perForm.id = res.org.id;
            _this.perForm.see = res.select.join(',');
            _this.select = res.select;
            _this.optionalOrgList = res.optionalList;
          })
          .catch(err => {
            _this.perLoading = false;
            _this.$message.error(err.msg);
          })
    },
    permissionFinish() {
      const _this = this;
      _this.perLoading = true;
      _this.select = _this.$refs.permission.getCheckedKeys();
      _this.perForm.see = _this.select.join(',');
      adminOrgSee(_this.perForm)
          .then(res => {
            _this.perLoading = false;
            _this.perStatus = false;
            _this.loadList();
            _this.$message({
              type: 'success',
              message: '操作成功!'
            });
          })
          .catch(err => {
            _this.perLoading = false;
            _this.$message.error(err.msg);
          })
    },
  },
};
</script>

<style scoped>
.custom-tree-node {
  display: flex;
  justify-content: space-between;
}

.custom-tree-node-name {
  width: 400px;
}
</style>