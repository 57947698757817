<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">订单状态</h3>
            <div class="box-tools pull-right">
              <el-button v-if="auth.adminOrderStatusCreate" @click="append">新增</el-button>
            </div>
          </div>
          <div class="box-body no-padding" v-if="auth.adminOrderStatusList">
            <el-table
                :data="list"
                border
                v-loading="listLoading"
                style="width: 100%">
              <el-table-column
                  prop="name"
                  label="描述"
              >
              </el-table-column>
              <el-table-column label="重复提交">
                <template slot-scope="scope">
                  <div style="color: #00a65a;" v-if="scope.row.isRepeat === 1">可以</div>
                  <div style="color: #999999;" v-if="scope.row.isRepeat === 0">不可以</div>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button
                      type="text"
                      size="small"
                      v-if="auth.adminOrderStatusUpdate"
                      @click="edit(scope.row)"
                  >
                    编辑
                  </el-button>
                  <el-button
                      type="text"
                      size="small"
                      v-if="auth.adminOrderStatusDelete"
                      @click="remove(scope.row)"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="box-body" v-if="!auth.adminOrderStatusList">
            <div>没有权限</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新增 -->
    <div v-if="auth.adminOrderStatusCreate">
      <el-dialog
          title="新增状态"
          :modal-append-to-body="true"
          :append-to-body="true"
          :close-on-click-modal="false"
          :visible.sync="addStatus"
          v-if="addStatus"
      >
        <el-form ref="orderStatusAddForm" label-width="160px" :model="addForm">
          <el-form-item
              label="状态名称"
              prop="name"
              :rules="[{ required: true, message: '请输入状态名称', trigger: 'blur' }]"
          >
            <el-input v-model="addForm.name" placeholder="请输入状态名称"></el-input>
          </el-form-item>
          <el-form-item
              label="是否可以重复提交"
              prop="isRepeat"
              :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
          >
            <el-radio-group v-model="addForm.isRepeat">
              <el-radio :label="1" :value="1">可以</el-radio>
              <el-radio :label="0" :value="0">不可以</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="appendFinish">立即创建</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>

    <!-- 修改 -->
    <div v-if="auth.adminOrderStatusUpdate">
      <el-dialog
          title="修改状态信息"
          :modal-append-to-body="true"
          :append-to-body="true"
          :close-on-click-modal="false"
          :visible.sync="editStatus"
          v-if="editStatus"
      >
        <el-form ref="orderStatusEditForm" label-width="160px" :model="editForm">
          <el-form-item
              label="状态名称"
              prop="name"
              :rules="[{ required: true, message: '请输入状态名称', trigger: 'blur' }]"
          >
            <el-input v-model="editForm.name" placeholder="请输入状态名称"></el-input>
          </el-form-item>
          <el-form-item
              label="是否可以重复提交"
              prop="isRepeat"
              :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
          >
            <el-radio-group v-model="editForm.isRepeat">
              <el-radio :label="1">可以</el-radio>
              <el-radio :label="0">不可以</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="editFinish">保存修改</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import qs from "qs";
import {
  adminOrderStatusDelete,
  adminOrderStatusList,
  adminOrderStatusUpdate,
  adminOrderStatusUpdateDetail,
  adminOrderStatusCreate,
} from "../../api"

export default {
  components: {},
  data() {
    return {
      user: {},
      auth: {},
      params: {},
      query: {},
      list: [],
      listLoading: false,
      // 新增
      addStatus: false,
      addLoading: false,
      addForm: {
        name: '',
        isRepeat: 0,
      },
      // 修改
      editStatus: false,
      editLoading: false,
      editForm: {
        id: '',
        name: '',
        isRepeat: 0,
      },
      // 删除
      delLoading: false,
    };
  },
  computed: mapState({
    config: (state) => state.config,
  }),
  mounted() {
    const _this = this;
    _this.query = Object.assign(_this.query, _this.$route.query);
    _this.query.isRepeat = parseInt(_this.query.isRepeat);
    _this.params = Object.assign(_this.params, _this.$route.params);
    _this.authInit(_this).then((res) => {
      _this.user = res.user;
      _this.auth = res.auth;
      if (_this.auth.adminOrderStatusList) {
        _this.loadList();
      }
    });
  },
  methods: {
    ...mapActions(["authInit"]),
    updateUrl() {
      const _this = this;
      const url = _this.$route.path + "?" + qs.stringify(_this.query);
      _this.$router.replace({path: url});
    },
    // 获取列表
    loadList() {
      const _this = this;
      _this.listLoading = true;
      adminOrderStatusList(_this.query)
          .then(res => {
            _this.listLoading = false;
            _this.list = res.statusList;
            _this.updateUrl();
          })
          .catch(err => {
            _this.listLoading = false;
            _this.$message.error(err.msg);
          })
    },
    // 新增
    append(item) {
      const _this = this;
      _this.addForm.name = '';
      _this.addStatus = true;
    },
    appendFinish() {
      const _this = this;
      _this.$refs.orderStatusAddForm.validate((valid) => {
        if (valid) {
          _this.addLoading = true;
          adminOrderStatusCreate(_this.addForm)
              .then(res => {
                _this.addLoading = false;
                _this.addStatus = false;
                _this.loadList();
                _this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
              })
              .catch(err => {
                _this.addLoading = false;
                _this.$message.error(err.msg);
              })
        } else {
          return false;
        }
      });
    },
    // 修改
    edit(item) {
      const _this = this;
      _this.editLoading = true;
      adminOrderStatusUpdateDetail({id: item.id})
          .then(res => {
            _this.editLoading = false;
            _this.editStatus = true;
            _this.editForm.id = res.status.id;
            _this.editForm.name = res.status.name;
            _this.editForm.isRepeat = res.status.isRepeat;
          })
          .catch(err => {
            _this.editLoading = false;
            _this.$message.error(err.msg);
          })
    },
    editFinish() {
      const _this = this;
      _this.$refs.orderStatusEditForm.validate((valid) => {
        if (valid) {
          _this.editLoading = true;
          adminOrderStatusUpdate(_this.editForm)
              .then(res => {
                _this.editLoading = false;
                _this.editStatus = false;
                _this.loadList();
                _this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
              })
              .catch(err => {
                _this.editLoading = false;
                _this.$message.error(err.msg);
              })
        } else {
          return false;
        }
      })
    },
    // 删除
    remove(item) {
      const _this = this;
      if (_this.auth.adminOrderStatusDelete) {
        _this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.delLoading = true;
          adminOrderStatusDelete({id: item.id})
              .then(res => {
                _this.delLoading = false;
                _this.loadList();
                _this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
              })
              .catch(err => {
                _this.delLoading = false;
                _this.$message.error(err.msg);
              })
        }).catch(() => {
        });
      } else {
        _this.$message.error('没有操作权限');
      }
    },
  },
};
</script>

<style scoped>
.custom-tree-node {
  display: flex;
  justify-content: space-between;
}

.custom-tree-node-name {
  width: 400px;
}
</style>