<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">角色列表信息（可拖动改动层级结构）</h3>
            <div class="box-tools pull-right"></div>
          </div>
          <div class="box-body" v-if="auth.adminRoleList">
            <el-tree
                v-loading="roleListLoading"
                :data="roleList"
                :props="defaultProps"
                :expand-on-click-node="false"
                default-expand-all
                @node-drop="handleDrop"
                draggable
            >
              <div class="custom-tree-node" slot-scope="{ node, data }">
                <div class="custom-tree-node-name">{{ data.name }}</div>
                <div>
                  <el-button
                      v-if="auth.adminRoleCreate"
                      type="text"
                      size="mini"
                      :disabled="addLoading"
                      @click="append(data)">
                    新增下级
                  </el-button>
                  <el-button
                      type="text"
                      size="mini"
                      v-if="!data.isTop && auth.adminRoleUpdate"
                      :disabled="editLoading"
                      @click="edit(data)">
                    修改
                  </el-button>
                  <el-button
                      type="text"
                      size="mini"
                      v-if="!data.isTop && auth.adminRoleRoute"
                      :disabled="perLoading"
                      @click="permission(data)">
                    设置角色权限
                  </el-button>
                  <el-button
                      type="text"
                      size="mini"
                      v-if="data.isDel && auth.adminRoleDelete"
                      :disabled="delLoading"
                      @click="remove(data)">
                    删除
                  </el-button>
                </div>
              </div>
            </el-tree>
          </div>
          <div class="box-body" v-if="!auth.adminRoleList">
            <div>没有权限</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新增 -->
    <div v-if="auth.adminRoleCreate">
      <el-dialog
          title="新增角色"
          :modal-append-to-body="true"
          :append-to-body="true"
          :close-on-click-modal="false"
          :visible.sync="addStatus"
          v-if="addStatus"
      >
        <el-form ref="roleAddForm" label-width="80px" :model="addForm">
          <el-form-item
              label="角色名称"
              prop="name"
              :rules="[{ required: true, message: '请输入角色名称', trigger: 'blur' }]"
          >
            <el-input v-model="addForm.name" placeholder="请输入角色名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="appendFinish">立即创建</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>

    <!-- 修改 -->
    <div v-if="auth.adminRoleUpdate">
      <el-dialog
          title="修改角色信息"
          :modal-append-to-body="true"
          :append-to-body="true"
          :close-on-click-modal="false"
          :visible.sync="editStatus"
          v-if="editStatus"
      >
        <el-form ref="roleEditForm" label-width="80px" :model="editForm">
          <el-form-item
              label="角色名称"
              prop="name"
              :rules="[{ required: true, message: '请输入角色名称', trigger: 'blur' }]"
          >
            <el-input v-model="editForm.name" placeholder="请输入角色名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="editFinish">保存修改</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>

    <!-- 权限 -->
    <div v-if="auth.adminRoleRoute">
      <el-drawer
          title="修改角色权限"
          size="80%"
          :visible.sync="perStatus"
          :append-to-body="true"
          :wrapperClosable="true"
      >
        <el-form ref="form" label-width="80px">
          <el-form-item label="选择权限">
            <el-tree
                ref="permission"
                :data="optionalPerList"
                show-checkbox
                node-key="id"
                :expand-on-click-node="false"
                default-expand-all
                :default-checked-keys="select"
                :props="perProps">
            </el-tree>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="permissionFinish">保存设置</el-button>
          </el-form-item>
        </el-form>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import qs from "qs";
import {
  adminRoleDelete,
  adminRoleList,
  adminRoleUpdateDetail,
  adminRoleUpdate,
  adminRoleCreate,
  adminRoleRouteDetail,
  adminRoleRoute
} from "../../api"

export default {
  components: {},
  data() {
    return {
      user: {},
      auth: {},
      params: {},
      query: {},
      roleList: [],
      roleListLoading: false,
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      // 新增
      addStatus: false,
      addLoading: false,
      addForm: {
        parentId: 0,
        name: ''
      },
      // 修改
      optionalList: [],
      editStatus: false,
      editLoading: false,
      editForm: {
        id: '',
        parentId: 0,
        name: ''
      },
      // 删除
      delLoading: false,
      // 权限
      select: [],
      optionalPerList: [],
      perStatus: false,
      perLoading: false,
      perForm: {
        id: '',
        permission: '',
      },
      perProps: {
        children: 'children',
        label: 'name',
      }
    };
  },
  computed: mapState({
    config: (state) => state.config,
  }),
  mounted() {
    const _this = this;
    _this.query = Object.assign(_this.query, _this.$route.query);
    _this.params = Object.assign(_this.params, _this.$route.params);
    _this.loading = true;
    _this.authInit(_this).then((res) => {
      _this.user = res.user;
      _this.auth = res.auth;
      if (_this.auth.adminRoleList) {
        _this.loadList();
      }
    });
  },
  methods: {
    ...mapActions(["authInit"]),
    updateUrl() {
      const _this = this;
      const url = _this.$route.path + "?" + qs.stringify(_this.query);
      _this.$router.replace({path: url});
    },
    // 获取列表
    loadList() {
      const _this = this;
      _this.roleListLoading = true;
      adminRoleList(_this.query)
          .then(res => {
            _this.roleListLoading = false;
            _this.roleList = res.roleList;
            _this.updateUrl();
          })
          .catch(err => {
            _this.roleListLoading = false;
            _this.$message.error(err.msg);
          })
    },
    // 新增
    append(item) {
      const _this = this;
      _this.addForm.parentId = item.id;
      _this.addForm.name = '';
      _this.addStatus = true;
    },
    appendFinish() {
      const _this = this;
      _this.$refs.roleAddForm.validate((valid) => {
        if (valid) {
          _this.addLoading = true;
          adminRoleCreate(_this.addForm)
              .then(res => {
                _this.addLoading = false;
                _this.addStatus = false;
                _this.loadList();
                _this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
              })
              .catch(err => {
                _this.addLoading = false;
                _this.$message.error(err.msg);
              })
        } else {
          return false;
        }
      });
    },
    // 修改
    edit(item) {
      const _this = this;
      _this.editLoading = true;
      adminRoleUpdateDetail({id: item.id})
          .then(res => {
            _this.editLoading = false;
            _this.editStatus = true;
            _this.editForm.id = res.role.id;
            _this.editForm.parentId = res.role.parentId;
            _this.editForm.name = res.role.name;
            _this.optionalList = res.optionalList;
          })
          .catch(err => {
            _this.editLoading = false;
            _this.$message.error(err.msg);
          })
    },
    editFinish() {
      const _this = this;
      _this.$refs.roleEditForm.validate((valid) => {
        if (valid) {
          _this.editLoading = true;
          adminRoleUpdate(_this.editForm)
              .then(res => {
                _this.editLoading = false;
                _this.editStatus = false;
                _this.loadList();
                _this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
              })
              .catch(err => {
                _this.editLoading = false;
                _this.$message.error(err.msg);
              })
        } else {
          return false;
        }
      })
    },
    handleDrop(raw, to, type) {
      const _this = this;
      _this.$confirm('是否执行当前拖动操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.editLoading = true;
        _this.editForm.id = raw.data.id;
        _this.editForm.name = raw.data.name;
        if (type === 'inner') {
          _this.editForm.parentId = to.data.id;
        } else {
          _this.editForm.parentId = to.data.parentId;
        }
        adminRoleUpdate(_this.editForm)
            .then(res => {
              _this.editLoading = false;
              _this.editStatus = false;
              _this.loadList();
              _this.$message({
                type: 'success',
                message: '操作成功!'
              });
            })
            .catch(err => {
              _this.editLoading = false;
              _this.$message.error(err.msg);
              _this.loadList();
            })
      }).catch(() => {
      });
    },
    // 删除
    remove(item) {
      const _this = this;
      if (_this.auth.adminRoleDelete) {
        _this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.delLoading = true;
          adminRoleDelete({id: item.id})
              .then(res => {
                _this.delLoading = false;
                _this.loadList();
                _this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
              })
              .catch(err => {
                _this.delLoading = false;
                _this.$message.error(err.msg);
              })
        }).catch(() => {
        });
      } else {
        _this.$message.error('没有操作权限');
      }
    },
    // 权限
    permission(item) {
      const _this = this;
      _this.perLoading = true;
      adminRoleRouteDetail({id: item.id})
          .then(res => {
            _this.perLoading = false;
            _this.perStatus = true;
            _this.perForm.id = res.role.id;
            _this.perForm.permission = res.select.join(',');
            _this.select = res.select;
            _this.optionalPerList = res.optionalList;
          })
          .catch(err => {
            _this.perLoading = false;
            _this.$message.error(err.msg);
          })
    },
    permissionFinish() {
      const _this = this;
      _this.perLoading = true;
      _this.select = _this.$refs.permission.getCheckedKeys();
      _this.perForm.permission = _this.select.join(',');
      adminRoleRoute(_this.perForm)
          .then(res => {
            _this.perLoading = false;
            _this.perStatus = false;
            _this.loadList();
            _this.$message({
              type: 'success',
              message: '操作成功!'
            });
          })
          .catch(err => {
            _this.perLoading = false;
            _this.$message.error(err.msg);
          })
    },
  },
};
</script>

<style scoped>
.custom-tree-node {
  display: flex;
  justify-content: space-between;
}

.custom-tree-node-name {
  width: 400px;
}
</style>