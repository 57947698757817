import Vue from "vue";
import Vuex from "vuex";
import config from "./config";
import { adminAccountDetail } from "./api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    config: config,
    user: {
      user: {},
      auth: {},
      menuList: [],
    },
    currentPage: {
      title: '',
      url: '',
    },
  },
  getters: {},
  mutations: {
    updateUserInfo(state, user) {
      state.user = user;
    },
    updateCurrentPage(state, currentPage) {
      state.currentPage = currentPage;
    },
  },
  actions: {
    authInit({ commit }, _this) {
      const userInfoJson = _this.$localStorage.get("userInfo");
      if (userInfoJson) {
        commit("updateUserInfo", JSON.parse(userInfoJson));
      }
      return new Promise((resolve) => {
        adminAccountDetail()
          .then(userInfo => {
            commit("updateUserInfo", userInfo);
            _this.$localStorage.set("userInfo", JSON.stringify(userInfo));
            resolve(userInfo);
          })
          .catch(() => {
            _this.$localStorage.remove("session");
            _this.$localStorage.remove("userInfo");
            _this.$router.replace({ path: '/backend/guest/login' });
          })
      });
    },
  },
  modules: {},
});
