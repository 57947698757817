<template>
  <div class="hold-transition login-page" style="background: url('/static/image/photo24@2x.jpg')">
    <div class="login-big-box">
      <div class="login-left">
        <div class="login-left-img" style="background-image: url(/static/image/img-1.gif);">
        </div>
      </div>
      <div class="login-box">
        <div class="login-box-body">
          <h4 class="login-box-msg">登录你的账号</h4>
          <div style="height: 60px;">
            <el-alert v-if="loginErr" :title="loginErr" type="error" :closable="false">
            </el-alert>
          </div>
          <div class="form-group">
            <label for="account">登录账号</label>
            <input v-model="form.account" id="account" type="text" class="form-control" placeholder="请输入账号"
              autocomplete="off">
            <span class="help-block"></span>
          </div>

          <div class="form-group">
            <label for="password">登录密码</label>
            <input v-model="form.password" id="password" type="password" class="form-control" placeholder="请输入密码"
              autocomplete="off">
            <span class="help-block"></span>
          </div>

          <div style="display: flex;align-items: center;">
            <el-checkbox v-model="remember" @change="rememberAccount">记住登录</el-checkbox>
          </div>
          <div class="form-group">
            <div style="height: 10px;"></div>
            <el-button type="primary" style="width: 100%;" @click="login">登录</el-button>
          </div>
          <div style="height: 10px;"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import qs from "qs";

import { adminAccountLogin, adminAccountDetail } from "../../api"

export default {
  components: {},
  data() {
    return {
      params: {},
      query: {},
      loginErr: '',
      form: {
        account: '',
        password: '',
        type: 1
      },
      remember: false,
    };
  },
  computed: mapState({
    config: (state) => state.config,
  }),
  mounted() {
    const _this = this;
    _this.query = Object.assign(_this.query, _this.$route.query);
    _this.params = Object.assign(_this.params, _this.$route.params);
    _this.loadList()
  },
  methods: {
    updateUrl() {
      const _this = this;
      const url = _this.$route.path + "?" + qs.stringify(_this.query);
      _this.$router.replace({ path: url });
    },
    loadList() {
      const _this = this;
      const formJson = _this.$localStorage.get("loginRemember");
      if (formJson) {
        _this.form = JSON.parse(formJson);
        _this.remember = true;
      } else {
        _this.remember = false;
      }
      _this.updateUrl();
    },
    // 记住登录
    rememberAccount() {
      const _this = this;
      if (_this.remember) {
        _this.$localStorage.set("loginRemember", JSON.stringify(_this.form));
      } else {
        _this.$localStorage.remove("loginRemember");
      }
    },
    // 登录
    login() {
      const _this = this;
      _this.loginErr = '';
      adminAccountLogin(_this.form)
        .then(res => {
          _this.$localStorage.set("session", res.token);
          adminAccountDetail()
            .then(userInfo => {
              _this.$localStorage.set("userInfo", JSON.stringify(userInfo));
              _this.$router.replace({ path: '/backend/bus/order' })
            })
            .catch((err) => {
              _this.$localStorage.remove("session");
              _this.$localStorage.remove("userInfo");
              _this.loginErr = err.msg;
            })
        })
        .catch(err => {
          _this.loginErr = err.msg;
        })
    }
  },
}
</script>

<style scoped>
.login-page {
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-big-box {
  display: flex;
  background-color: #ffffff;
  height: 480px;
  overflow: hidden;
}

.login-box {
  display: block;
}

.form-group {
  margin-bottom: 6px;
}

.form-group label {
  font-weight: normal;
  color: #666666;
}

.login-box-msg {
  padding-top: 10px;
}

.help-block {
  display: block;
  height: 20px;
  margin-bottom: 0;
}

.login-left {
  padding: 10px;
  overflow: hidden;
}

.login-left-img {
  width: 400px;
  height: 100%;
  border-radius: 4px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
}

.login-left-logo {
  margin-top: 10px;
  margin-left: 10px;
}
</style>